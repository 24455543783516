@import "~@blueprintjs/core/lib/scss/variables";

.app {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 20px;
	overflow-y: scroll;
	color: $pt-text-color;
	background: $pt-app-background-color;
	.bp4-navbar.bp4-elevation-3 {
		box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
	}
	&.bp4-dark {
		color: $pt-dark-text-color;
		background: $pt-dark-app-background-color;
		.bp4-navbar.bp4-elevation-3 {
			box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4) !important;
		}
	}
	.bp4-navbar {
		position: sticky;
		top: 20px;
		left: 0;
	}
	.bp4-navbar-heading {
		min-width: 10ch;
		margin: 0;
	}
	.bp4-navbar, .bp4-card {
		border-radius: 3px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	.bp4-navbar, & .page-wrap > .bp4-card {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}
	.cards {
		margin-top: -$pt-navbar-height;
	}
	.page-wrap:last-child {
		height: calc(100vh - $pt-navbar-height + 30px);
	}
	.navbarSpacer {
		opacity: 50%;
		background: transparent;
		height: $pt-navbar-height;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}
