
.map {
	width: 80%;
	margin-left: 10%;
	margin-top: 20px;
	margin-bottom: 60px;
	max-height: 600px;
	padding: 0;
	cursor: crosshair;
}
